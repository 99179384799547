import React from "react";

import Layout from "../components/layout/layout";

import SEO from "../components/seo";

const SP = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO title="Souvenir Programme Booklet" />

        <section className="max-w-5xl px-2 pt-1 pb-10 mx-auto sm:w-4/5">
          <div className="absolute inset-0">
            <div className="bg-white h-1/3 sm:h-2/3" />
          </div>
          <div className="relative mx-auto max-w-7xl">
            <div className="text-center">
              <h1 className="text-2xl font-extrabold tracking-tight text-swa-1 sm:text-3xl">
              Singapore Women's Association 70th Anniversary Celebration
              </h1>
              <h3 className="text-xl  text-swa-1 sm:text-xl">
                47th SWA Annual Charity Gala 2024
              </h3>
              <iframe className="hidden md:block mx-auto mt-5" width="760" height="415" src="https://www.youtube.com/embed/2nAGhV1k30s?si=bzxSUfqwWCpxTEkB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

              <iframe className="md:hidden mx-auto mt-5" width="400" height="215" src="https://www.youtube.com/embed/2nAGhV1k30s?si=bzxSUfqwWCpxTEkB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              <div className="inline-block mt-5 rounded-md shadow">
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/swa-admin-23d1e.appspot.com/o/download%2F47th%20Charity%20Dinner%20e-booklet-compressed.pdf?alt=media&token=8d60ab25-ec85-42db-9dcf-c8bfa792ce71&utm_campaign=charitydinner&utm_source=website"
                  className="flex items-center justify-center px-3 py-3 mt-3 text-lg font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
                  target="_blank"
                >
                  Download Souvenir Programme Booklet (PDF 6MB)
                </a>
              </div>
              <p className="max-w-2xl mx-auto mt-3 text-lg text-gray-500 sm:mt-4">
                We're excited to celebrate the 70th anniversary of the Singapore
                Women's Association. Inside, you'll find details on the event
                schedule, photos of the finalists for the Miss Singapore Pageant
                International 2024, and information about prizes for Guess The
                Winner. The booklet also provides an overview of the Singapore
                Women's Association 70 years history. We hope you enjoy this
                special evening filled with pageantry, philanthropy, and
                community. Thank you for your support.
              </p>


              <img
                src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1720663662/participatePage/kdxua2eefnih4lbk2nug.webp"
                alt="SWA Charity Gala"
                width={1000}
                height={700}
                className="mx-auto mt-5 sm:max-w-xl"
              />
                            <div className="inline-block mt-5 rounded-md shadow">
            
            <a
              href="https://firebasestorage.googleapis.com/v0/b/swa-admin-23d1e.appspot.com/o/download%2F47th%20Charity%20Dinner%20e-booklet-compressed.pdf?alt=media&token=8d60ab25-ec85-42db-9dcf-c8bfa792ce71&utm_campaign=charitydinner&utm_source=website"
              className="flex items-center justify-center px-3 py-3 text-lg font-medium text-white border border-transparent rounded-md bg-swa-1 hover:bg-swa-3"
              target="_blank"
            >
              Download Souvenir Programme Booklet (PDF 6MB)
            </a>
          </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default SP;
